import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Seo from "src/utils/Seo"
import Breadcrumbs from "src/utils/Breadcrumbs"
import MasonryLayout from "src/utils/MasonryLayout"
import Fade from "react-reveal/Fade"

const uniqBy = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];

  return [...arr.reduce((map, item) => {
    const key = (item === null || item === undefined) ?
      item : cb(item);

    map.has(key) || map.set(key, item);

    return map;
  }, new Map()).values()];
};

const LightboxGallery = ({ item, onClick, isActive, onClickNav }) => {

  const target =
    item && item.linked_product.type &&
    "/" +
    item.linked_product.type.replace("_", "-") +
    "s/styles/" +
    item.linked_product.slug + '/'

  return (
    <div className={`z-[9999999999] w-screen h-screen bg-offwhite transition-all duration-500 ease-in-out flex flex-col ${isActive ? "visible opacity-1" : "invisible opacity-0"} justify-center fixed top-0 left-0 py-28`}>
      {item?.video && (
        <video
          width="100%"
          height="100%"
          srl_video_muted="true"
          srl_video_caption={item.alt}
          style={{ display: "none" }}
        >
          <track kind="captions" />
          <source src={item.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {!item?.video && (
        <img className="self-center max-h-full"
          src={item?.image.split("&rect").shift()}
          alt={item?.image.alt}
        />
      )}
      {
        item?.linked_product.slug ? (
          <Link
            className=" text-gold font-display hover:underline text-1xl lg:text-2xl mx-auto mt-5 text-center"
            to={`${target}`}
          >
            {item.alt}
          </Link>
        ) : (
          <span className=" text-gold font-display text-1xl lg:text-2xl mx-auto mt-5 text-center">{item?.alt}</span>
        )
      }
      <button className="absolute h-12 w-12 flex items-center justify-center right-0 top-0" onClick={() => onClick(item)}>
        <i className="fal fa-times fa-lg text-darkgrey"></i>
      </button>
      <button className="absolute h-12 w-12 flex items-center justify-center left-0 bottom-0 lg:top-1/2 transform -translate-y-1/2" onClick={() => onClickNav(item.uid - 1)}>
        <i className="fal fa-chevron-left fa-lg text-darkgrey"></i>
      </button>
      <button className="absolute h-12 w-12 flex items-center justify-center right-0 bottom-0 lg:top-1/2 transform -translate-y-1/2" onClick={() => onClickNav(item.uid + 1)}>
        <i className="fal fa-chevron-right fa-lg text-darkgrey"></i>
      </button>

    </div>
  )
}

const ProductGalleryItems = ({ cards, lightboxData, lightboxClick, lightboxActive, lightboxNavClick }) => {

  return (
    <>
      <Fade>
        <MasonryLayout columns={5} gap={15} className="px-4">
          {cards
            .filter((item) => {
              return item.isVisible.length > 0
            })
            .map((item, index) => {
              return (
                <div
                  className={`${item.filter.join(
                    " "
                  )} w-full h-full object-cover`}
                  key={index}
                  isVisible={item.isVisible.length}
                >
                  <Fade>
                    {
                      <button className="block relative" onClick={() => { lightboxClick(item) }}>
                        <img
                          className="w-full transition-all duration-500 ease-in-out hover:opacity-70 overflow-hidden"
                          src={item.thumbnail.src}
                          alt={item.alt}
                        />
                        {
                          item.video &&
                          <img
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mb-3"
                            src={"/video.svg"}
                            alt="Video play button"
                          />
                        }
                      </button>
                    }
                  </Fade>
                </div>
              )
            })}
        </MasonryLayout>
      </Fade>
      <LightboxGallery item={lightboxData} onClick={lightboxClick} isActive={lightboxActive} onClickNav={lightboxNavClick} />
    </>
  )
}

const ProductGallery = () => {
  const query = useStaticQuery(graphql`
    query productGalleryQuery {
      prismicProductGallery {
        data {
          title {
            text
          }
          body {
            ... on PrismicProductGalleryDataBodyGalleryItem {
              items {
                tags {
                  document {
                    ... on PrismicTag {
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
              primary {
                linked_product {
                  slug
                  type
                }
                video {
                  url
                }
                image {
                  alt
                  fluid {
                    src
                  }
                  thumbnails {
                    LandscapeThumb {
                      fluid(maxWidth: 400) {
                        ...GatsbyPrismicImageFluid
                      }
                    }
                    PortraitThumb {
                      fluid(maxWidth: 400) {
                        ...GatsbyPrismicImageFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [filterKey, setFilterKey] = useState("*")
  const [lightboxActive, setLightboxActive] = useState(false)
  const [lightboxData, setLightboxData] = useState(null)

  const onFilter = key => () => {
    setFilterKey(key)
    updateCards(key)
  }

  const { data } = query.prismicProductGallery

  useEffect(() => {
    data.body.map((item, i) => {
      return () => {
        const img = new Image()
        img.src = item.primary.image.fluid.src
      }
    })
    data.body.map((item, i) => {
      return () => {
        const img = new Image()
        img.src = item.primary.image.thumbnails.LandscapeThumb.fluid.src
      }
    })
    data.body.map((item, i) => {
      return () => {
        const img = new Image()
        img.src = item.primary.image.thumbnails.PortraitThumb.fluid.src
      }
    })
  }, [data])

  const filtersDefault = data.body.flatMap(item => {
    return item.items.flatMap(tag => {
      if (tag.tags.document) {
        if (tag.tags.document.data) {
          return {
            label: tag.tags.document.data.title.text,
            class: tag.tags.document.data.title.text
              .replace(" ", "_")
              .toLowerCase(),
          }
        }
      }
      return false
    })
  })

  const [cards, setCards] = useState(
    data.body.map((item, index) => {
      return {
        uid: index,
        image: item.primary.image.fluid.src,
        alt: item.primary.image.alt,
        thumbnail:
          index % 2 === 0
            ? item.primary.image.thumbnails.LandscapeThumb.fluid
            : item.primary.image.thumbnails.PortraitThumb.fluid,
        thumbnailfull:
          index % 2 === 0 ? item.primary.image.fluid : item.primary.image.fluid,
        video: item.primary.video.url,
        linked_product: item.primary.linked_product,
        filter: item.items.map(tag => {
          if (tag.tags.document) {
            if (tag.tags.document.data) {
              return tag.tags.document.data.title.text
                .replace(" ", "_")
                .toLowerCase()
            }
            return null
          }
          return null
        }),
        isVisible: item.items.filter(tag => {
          return (
            tag.tags.document?.data?.title?.text
              .replace(" ", "_")
              .toLowerCase() === filterKey || filterKey === "*"
          )
        }),
      }
    })
  )

  const lightboxClick = item => {
    setLightboxActive(!lightboxActive)
    setLightboxData(item)
  }

  const lightboxNavClick = uid => {
    if (uid > -1 && uid < cards.length) {
      setLightboxData(cards[uid])
    }
  }

  const updateCards = key => {
    setCards(
      data.body.filter(item => {
        const tags = item.items.filter(tag => {
          return (
            tag.tags.document.data.title.text
              .replace(" ", "_")
              .toLowerCase() === key || key === "*"
          )
        })
        return tags.length ? true : false
      }).map((item, index) => {
          const isVisible = item.items.filter(tag => {
            return (
              tag.tags.document.data.title.text
                .replace(" ", "_")
                .toLowerCase() === key || key === "*"
            )
          })

          if (isVisible.length) {
            return {
              uid: index,
              image: item.primary.image.fluid.src,
              alt: item.primary.image.alt,
              thumbnail:
                index % 2 === 0
                  ? item.primary.image.thumbnails.LandscapeThumb.fluid
                  : item.primary.image.thumbnails.PortraitThumb.fluid,
              thumbnailfull:
                index % 2 === 0
                  ? item.primary.image.fluid
                  : item.primary.image.fluid,
              video: item.primary.video.url,
              linked_product: item.primary.linked_product,
              filter: item.items.map(tag => {
                if (tag.tags.document) {
                  if (tag.tags.document.data) {
                    return tag.tags.document.data.title.text
                      .replace(" ", "_")
                      .toLowerCase()
                  }
                  return null
                }
                return null
              }),
              isVisible: item.items.filter(tag => {
                return (
                  tag.tags.document.data.title.text
                    .replace(" ", "_")
                    .toLowerCase() === key || key === "*"
                )
              }),
            }
          }
          return null
        })
    )
  }

  const filters = [
    { label: "Show All", class: "*" },
    ...uniqBy(filtersDefault, "class"),
  ]

  function BreadcrumbsRender(props) {
    const crumbs = [
      { label: "Inspiration", href: `/inspiration` },
      {
        label: "Product Gallery",
        href: `/inspiration/product-gallery`,
        active: true,
      },
    ]
    return (
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
    )
  }

  return (
    <>
      <Seo
        title="Product Gallery | Front, Garage &amp; Internal Doors |"
        description="Hand crafted in our Yorkshire based factory, our doors are made-to-measure and always unique as per your needs. Take a look at our stunning collection of previous projects."
      />
      <BreadcrumbsRender />
      <section className="container">
        <Fade distance="20px" bottom>
          <h1 className="font-display text-gold rfs:text-5xl text-center w-full">
            {data.title.text}
          </h1>
          <div className="w-24 separator mt-5 mx-auto block"></div>
        </Fade>
      </section>
      <section>
        <Fade>
          <div className="block bg-white py-6 mb-10">
            <div className="flex flex-row justify-start md:justify-center items-center space-x-3 flex-nowrap overflow-x-scroll scrollbar-none px-6">
              <Fade>
                {filters.map((f, i) => {
                  return (
                    <span
                      key={i}
                      className={`btn btn-black cursor-pointer font-bold text-xxs whitespace-nowrap ${filterKey === f.class ? "active" : ""
                        }`}
                      onClick={onFilter(f.class)}
                      onKeyDown={onFilter(f.class)}
                      role="button"
                      tabIndex={i}
                    >
                      {f.label}
                    </span>
                  )
                })}
              </Fade>
            </div>
          </div>
        </Fade>
      </section>
      <section>
        <ProductGalleryItems {...{ cards, lightboxData, lightboxClick, lightboxActive, lightboxNavClick }} />
      </section>
    </>
  )
}

export default ProductGallery
